import {
  NgModule,
  CUSTOM_ELEMENTS_SCHEMA,
  APP_INITIALIZER,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {
  HTTP_INTERCEPTORS,
  HttpBackend,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { QuidLibModule } from '@mhe/quid';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { environment } from '../environments/environment';
import {
  ConfigService,
  launchConfigs,
  ltiParams,
  TranslationConfigService,
  UPModule,
} from 'up';
import { LoaderInterceptor } from './shared/interceptors/loader.interceptor';
import { InterceptorErrorComponent } from './shared/interceptors/interceptor-error/interceptor-error.component';
import { HeaderComponent } from './shared/components/header/header.component';
import { ErrorComponent } from './shared/components/error/error.component';
import { Store, StoreModule } from '@ngrx/store';
import { appReducers } from './shared/store/reducers/app.reducers';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { SetLtiParams } from './shared/store/actions/ltiparams.action';
import { CommonModule } from '@angular/common';

export function createTranslateLoader(httpBackend: HttpBackend) {
  return new MultiTranslateHttpLoader(httpBackend, [
    './assets/quid-i18n/',
    './assets/i18n/',
    './assets/up-i18n/',
  ]);
}

export function initializeApplication(
  config: ConfigService,
  store: Store,
  translationConfigService: TranslationConfigService,
) {
  return (): Promise<void> => {
    const launchConfigs: launchConfigs = {
      createTokenApiUrl: `${environment.apiUrl}/up/jwt/token/create`,
      paramsApiUrl: `${environment.apiUrl}/ebook/params`,
      redirectUrl: '', //'https://www.mheducation.com/highered', TBD
      environmentName: environment.envName,
    };
    return config.processRequest(launchConfigs).then(
      (ltiResponse: ltiParams) => {
        const ltiParams = {
          ltiParams: ltiResponse,
        };
        store.dispatch(new SetLtiParams(ltiParams));
        translationConfigService.setLocale(ltiParams.ltiParams.lmsLocale);
      },
      () => {
        translationConfigService.setLocale();
      },
    );
  };
}

@NgModule({
  declarations: [
    AppComponent,
    InterceptorErrorComponent,
    HeaderComponent,
    ErrorComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    CommonModule,
    FormsModule,
    AppRoutingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpBackend],
      },
    }),
    UPModule,
    NgbModule,
    QuidLibModule,
    StoreModule.forRoot(appReducers),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true,
    },
    { provide: Window, useValue: window },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApplication,
      deps: [ConfigService, Store, TranslationConfigService],
      multi: true,
    },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
