import { LtiActionsUnion, LtiStoreActions } from '../actions/ltiparams.action';
import {
  LtiParamsState,
  initialLtiParamsState,
} from '../state/ltiparams.state';

export const ltiReducer = (
  state = initialLtiParamsState,
  action: LtiActionsUnion,
): LtiParamsState => {
  switch (action.type) {
    case LtiStoreActions.SetLtiParams: {
      return {
        ...state,
        ltiParams: action.payload.ltiParams,
      };
    }
    case LtiStoreActions.AddModuleTitle: {
      let updatedLtiParams = { ...state.ltiParams };
      updatedLtiParams['moduleTitle'] = action.payload;

      return {
        ...state,
        ltiParams: updatedLtiParams,
      };
    }
    default:
      return state;
  }
};
