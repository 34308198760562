import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import {
  combineLatestWith,
  map,
  Observable,
  Subject,
  take,
  takeUntil,
} from 'rxjs';
import { ltiParams, UPUtilityService } from 'up';
import { AuthorizedUser } from 'up/lib/interfaces/access.interface';
import { environment } from '../environments/environment';
import { getLtiParams } from './shared/store/selectors/ltiparams.selector';
import { LtiParamsState } from './shared/store/state/ltiparams.state';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements OnDestroy {
  private destroy$ = new Subject<boolean>();
  ltiParams$: Observable<ltiParams> = this.store.select(getLtiParams);
  constructor(
    private router: Router,
    private store: Store<LtiParamsState>,
    private upUtilityService: UPUtilityService,
    private translate: TranslateService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    let role!: string,
      ltiaAction!: string,
      isAuthorized: boolean = false;
    const userAuthorizedData: AuthorizedUser = route?.data;
    return this.ltiParams$.pipe(
      combineLatestWith(
        this.translate.get([
          'GENERIC.UNAUTHORIZED.PAGE_TITLE',
          'GENERIC.PAGE_TITLE',
        ]),
      ),
      takeUntil(this.destroy$),
      take(1),
      map(([ltiParams, translatedText]) => {
        role = ltiParams.role.toLowerCase();
        ltiaAction = ltiParams.ltiaAction.toLowerCase();
        isAuthorized = this.upUtilityService.hasAuthorizedAccess(
          userAuthorizedData,
          role,
          ltiaAction,
        );
        if (!isAuthorized) {
          const title =
            translatedText['GENERIC.PAGE_TITLE'] +
            translatedText['GENERIC.UNAUTHORIZED.PAGE_TITLE'];

          this.router.navigate(['/unauthorized'], {
            state: {
              deleteUrl: `${environment.apiUrl}/ebook`,
              pageTitle: title,
            },
          });
        }
        return isAuthorized;
      }),
    );
  }
  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
